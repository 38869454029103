import React, { useContext, useEffect, useState, Suspense } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { adminDashboardEndpoint, adminGetAllQuestionSetQuestions, adminGetBankQuestionsMetaData } from "src/urls";
import { AppContext } from "src/context/AppContext";
import PageTemplate from "src/pages/PageTemplate";
import QuestionsMetaData from "src/components_with_stories/questions_meta_data/QuestionsMetaData";
import MockPaperAdminDashboard from "src/components_with_stories/questions_meta_data/MockPaperAdminDashboard";

import UpdateUserModal from "./components/UpdateUserModal";
import DateService from "src/utils/dateService";
import AdminRepository from "src/repository/adminRepository";
import useIsLightMode from "src/hooks/useIsLightMode";
import { loadingToast } from "src/components_with_stories/toast";
import { TextField } from "@mui/material";

const OurDataGrid = React.lazy(() => import("./components/OurDataGrid"));

const AdminDashboardPage = () => {
  const { isAuthenticated, isSuperuser } = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [questionsMetaData, setQuestionsMetaData] = useState(undefined);
  const [questionSetsMetaData, setQuestionSetsMetaData] = useState(undefined);

  const [searchTerm, setSearchTerm] = useState("");

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [user, setUser] = React.useState(null);

  const adminRepository = new AdminRepository();

  const isLightMode = useIsLightMode();

  useEffect(() => {
    if (isAuthenticated && isSuperuser) {
      axios
        .get(adminDashboardEndpoint)
        .then((response) => {
          const rawUsers = response.data;
          const users = rawUsers.map((user) => {
            user.date_joined = DateService.parseServerDate(user.date_joined);
            return user;
          });
          setUsers(users);
        })
        .catch((error) => console.log(error));

      axios
        .get(adminGetBankQuestionsMetaData)
        .then((response) => {
          const questionsMetaData = response.data;
          setQuestionsMetaData(questionsMetaData);
        })
        .catch((error) => console.log(error));

      axios
        .get(adminGetAllQuestionSetQuestions)
        .then((response) => {
          const questionSetsMetaData = response.data;
          setQuestionSetsMetaData(questionSetsMetaData);
        })
        .catch((error) => console.log(error));
    }
  }, [isAuthenticated, isSuperuser]);

  const updateUserNotes = (userId, notes) => {
    setUsers((prevUsers) => {
      return prevUsers.map((user) => {
        if (user.id === userId) {
          user.notes = notes;
        }
        return user;
      });
    });
  };

  if (!isAuthenticated) return <></>;
  if (!isSuperuser) return <></>;

  const columns = [
    { field: "name", headerName: "Name", minWidth: 160, flex: 1 },
    { field: "email", headerName: "Email", minWidth: 220, flex: 1.6 },
    {
      field: "phone",
      headerName: "Phone",
      width: 120,
    },
    {
      field: "date_joined",
      headerName: "Joined On",
      width: 140,
      valueFormatter: (cell) => {
        const dateJoined = cell.value;
        return DateService.formatFive(dateJoined);
      },
    },
  ];

  const actions = [
    {
      icon: <EditIcon />,
      label: "Edit",
      onClick: (user) => {
        setUser(user);
        setIsModalVisible(true);
      },
    },
    {
      icon: <FileDownloadIcon />,
      label: "Progress Report",
      onClick: async (user) => {
        loadingToast("Generating Report...", isLightMode);
        await adminRepository.generateProgressReport(user.id);
      },
    },
  ];

  const renderedUsers = users.filter((user) => {
    if (searchTerm === "") {
      return true;
    }
    const searchTermLowerCase = searchTerm.toLowerCase();
    return (
      user.name.toLowerCase().includes(searchTermLowerCase) || user.email.toLowerCase().includes(searchTermLowerCase)
    );
  });

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/admin-dashboard/" }]}>
        <title>Admin Dashboard | Pre-Reg Shortcuts</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <PageTemplate title="Admin Dashboard">
        <h2>Users</h2>

        <TextField
          id="search-basic"
          label="Filter Users by name or email address"
          variant="filled"
          value={searchTerm}
          fullWidth
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
          sx={{ mt: 0.5 }}
        />

        <Suspense fallback={<div>Loading Table...</div>}>
          <OurDataGrid rows={renderedUsers} columns={columns} actions={actions} />
        </Suspense>

        <h2>Mock Paper</h2>

        <p>
          Important: Calculations questions will be uploaded to Question Set with Mock Paper Order 1 and Clinical
          questions to Question Set with Mock Paper Order 2!
        </p>

        <MockPaperAdminDashboard questionSetsMetaData={questionSetsMetaData} />

        <h2 style={{ marginTop: "2rem" }}>Question Bank</h2>

        <QuestionsMetaData questionsMetaData={questionsMetaData} />

        {isModalVisible && (
          <UpdateUserModal user={user} handleClose={() => setIsModalVisible(false)} updateUserNotes={updateUserNotes} />
        )}
      </PageTemplate>
    </>
  );
};

export default AdminDashboardPage;

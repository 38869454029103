import { Container, LinearProgress, linearProgressClasses, styled, Typography } from "@mui/material";

import ElearningCourseDetailsHero from "./ElearningCourseDetailsHero";
import ElearningCourseDetailsSummary from "./ElearningCourseDetailsSummary";
import CourseModel from "src/models/CourseModel";
import CourseLessonProgressModel from "src/models/CourseLessonProgressModel";

export default function ElearningCourseView({
  course,
  courseLessonProgress,
  updateLessonProgress,
}: {
  course: CourseModel;
  courseLessonProgress: CourseLessonProgressModel[] | undefined;
  updateLessonProgress: (lessonProgress: CourseLessonProgressModel) => void;
}) {
  const courseProgress = getCourseProgressAsPercentage(course, courseLessonProgress);

  return (
    <>
      <ElearningCourseDetailsHero course={course} />

      <Container sx={{ py: { xs: 2.5, md: 5 } }}>
        {courseProgress !== null && (
          <>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Course Progress: {courseProgress.toFixed(0)}%
            </Typography>
            <BorderLinearProgress variant="determinate" value={courseProgress} />
          </>
        )}
      </Container>

      <Container
        sx={{
          overflow: "hidden",
          pb: { xs: 15, md: 10 },
        }}
      >
        <ElearningCourseDetailsSummary
          course={course}
          courseLessonProgress={courseLessonProgress}
          updateLessonProgress={updateLessonProgress}
        />
      </Container>
    </>
  );
}

const getNumLessonsInCourse = (course: CourseModel) => {
  const numLessons = course.chapters.reduce((acc, curr) => {
    return acc + curr.lessons.length;
  }, 0);
  return numLessons;
};

const getCourseProgressAsPercentage = (
  course: CourseModel,
  courseLessonProgress: CourseLessonProgressModel[] | undefined
) => {
  if (!courseLessonProgress) return null;

  const totalLessons = courseLessonProgress.length;

  // user has not started the course
  if (totalLessons === 0) return 0;

  const progresses = courseLessonProgress.map((clp) => {
    if (clp.watched) return 100;
    return clp.progress;
  });

  const numLessonsInCourse = getNumLessonsInCourse(course);

  const totalProgress = progresses.reduce((acc, curr) => acc + curr, 0);
  const overallProgressAsPercentage = totalProgress / numLessonsInCourse;
  return overallProgressAsPercentage;
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "rgb(0, 157, 71)",
  },
}));

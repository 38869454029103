import React, { useContext, useEffect, Suspense, useRef } from "react";
import Cookies from "js-cookie";

import { AppContext } from "../context/AppContext";
import { getLessonProgressEndpoint, saveLessonProgressEndpoint } from "../urls";
import axios from "axios";
import { successToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";

const VidstackPlayer = React.lazy(() => import("src/newComponents/VidstackPlayer"));

const NewPlayer = ({ videoTitle, videoUrl, lessonId, saveProgress, updateLessonProgress }) => {
  const [loadingVideoProgress, setLoadingVideoProgress] = React.useState(saveProgress);

  const [videoLength, setVideoLength] = React.useState(null);
  const [progress, setProgress] = React.useState({
    timeInSeconds: 0,
    percentWatched: 0,
  });
  const { isAuthenticated } = useContext(AppContext);
  const latestProgress = useRef(progress);

  const isLightMode = useIsLightMode();

  useEffect(() => {
    if (isAuthenticated && saveProgress) {
      axios
        .get(getLessonProgressEndpoint, {
          params: { lesson_id: lessonId },
        })
        .then((response) => {
          const { progress, progress_in_seconds } = response.data;
          setProgress({
            timeInSeconds: progress_in_seconds,
            percentWatched: progress,
          });
          // if user has watched more than 90% of the video, show a toast message
          // this lets them know that the video is resuming from where they left off
          if (progress > 90) successToast("Resuming video...", isLightMode, 5000);
        })
        .catch(() => {
          console.log("No progress found, starting lesson from beginning");
          setProgress({ timeInSeconds: 0, percentWatched: 0 });
        })
        .finally(() => {
          setTimeout(() => setLoadingVideoProgress(false), 100);
        });
    }
  }, [isAuthenticated]);

  // keep track of the latest progress to save when user closes the video
  useEffect(() => {
    latestProgress.current = progress;
  }, [progress]);

  // store data in progress variable
  const logActionOnVideo = (e, type) => {
    if (!saveProgress) return;
    if (!isAuthenticated) return;

    if (type === "onProgress" && videoLength === null) {
      setVideoLength(getVideoLengthInSeconds(e));
    } else if (type === "onTimeUpdate" && videoLength) {
      const currentTime = e.detail.currentTime;
      const percentWatched = Math.round((currentTime / videoLength) * 100);

      // if user has watched 2 seconds since last progress update
      // update the progress again
      const hasWatchedEnough = currentTime > progress.timeInSeconds + 2;

      // if user has rewinded 5 seconds or more since last progress update
      // update the progress again as they may have gone back to rewatch something
      const hasRewinded = currentTime < progress.timeInSeconds - 5;

      if (hasWatchedEnough || hasRewinded) {
        setProgress({
          timeInSeconds: currentTime,
          percentWatched: percentWatched,
        });
      }
    }
  };

  // api call to save progress
  const saveLessonProgress = (progress, action) => {
    updateLessonProgress &&
      updateLessonProgress({
        // arg type is CourseLessonProgressModel
        lesson: lessonId,
        progress: progress.percentWatched,
        progress_in_seconds: progress.timeInSeconds,
        watched: progress.percentWatched > 95,
      });
    fetch(saveLessonProgressEndpoint, {
      keepalive: true,
      method: "POST",
      headers: {
        "content-type": "application/json",
        "X-CSRFToken": Cookies.get("csrftoken"),
      },
      body: JSON.stringify({
        lesson_id: lessonId,
        progress: progress.percentWatched,
        progress_in_seconds: progress.timeInSeconds,
        action: action,
      }),
    });
  };

  // save progress when user closes the tab/browser
  useEffect(() => {
    if (isAuthenticated && saveProgress && progress) {
      const handler = () => saveLessonProgress(progress, "user closed tab/browser");
      window.addEventListener("pagehide", handler, true);
      return () => {
        window.removeEventListener("pagehide", handler, true);
      };
    }
  }, [progress]);

  // save progress when user closes the video
  useEffect(
    () => () => {
      if (isAuthenticated && saveProgress) {
        saveLessonProgress(latestProgress.current, "user closed video");
      }
    },
    []
  );

  const isLoading = loadingVideoProgress && isAuthenticated;

  return (
    <>
      {isLoading && <div>Loading...</div>}
      <Suspense fallback={<div>Loading...</div>}>
        {!isLoading && (
          <VidstackPlayer
            videoTitle={videoTitle}
            videoUrl={videoUrl}
            logActionOnVideo={logActionOnVideo}
            autoPlay={true}
            currentTime={progress.timeInSeconds}
          />
        )}
      </Suspense>
    </>
  );
};

export default NewPlayer;

function getVideoLengthInSeconds(e) {
  const video = e.trigger.target;
  return video.duration;
}

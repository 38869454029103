import { useTheme } from "@mui/material/styles";
import { Box, Stack, Button, AppBar, Toolbar, Container } from "@mui/material";

import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";

import { bgBlur } from "../../../utils/cssStyles";

import { HEADER } from "../../../config-global";

import Logo from "../../logo/Logo";

import { NavMobile, NavDesktop } from "../nav";
import HeaderShadow from "./HeaderShadow";
import { routes } from "../../../config/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import UserProfile from "./UserProfile";
import ShoppingCart from "./ShoppingCart";
import { BasketContext } from "src/context/BasketContext";
import EnrolNowButton from "src/newComponents/buttons/EnrolNowButton";

export const shouldHideHeader = (location) => {
  const inAppRoutes = routes.filter((r) => !r.isExternalLink);
  const routesToHideHeader = inAppRoutes.filter((r) => r.hideHeader);
  const pathsToHideHeader = routesToHideHeader.map((r) => (r.pathWithoutParams ? r.pathWithoutParams : r.path));
  const currentPath = location.pathname;
  const hideHeader = pathsToHideHeader.filter((path) => currentPath.includes(path)).length > 0;
  return hideHeader;
};

export const getLoginPageUrl = (window) => {
  const currentPage = window.location.pathname;
  if (currentPage.length > 3) {
    if (currentPage.startsWith("/")) {
      return "login/?next=" + currentPage.substring(1);
    }
    return "login/?next=" + currentPage;
  }
  return "login/";
};

export default function Header({ isStaging }) {
  const { isAuthenticated, logout, name, emailAddress, isSuperuser } = useContext(AppContext);
  const { items } = useContext(BasketContext);
  const numberOfItemsInBasket = items.length;

  const theme = useTheme();
  const navigate = useNavigate();

  const isMdUp = useResponsive("up", "md");

  const isOffset = useOffSetTop();

  const location = useLocation();
  const hideHeader = shouldHideHeader(location);
  if (hideHeader) return <></>;

  const dataRoutes = routes.filter((r) => !r.hideInHeader);
  const leftRoutes = dataRoutes.filter((item) => item.mdUpHome);
  const rightRoutes = dataRoutes.filter((item) => !item.mdUpHome);

  return (
    <AppBar color="transparent" sx={{ boxShadow: "none" }}>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(["height", "background-color"], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            color: "text.primary",
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              lineHeight: 0,
              position: "relative",
              marginBottom: "0.6rem",
            }}
          >
            {!isStaging && <Logo />}
            {isStaging && <span style={{ color: "red", fontSize: "1.5rem" }}>STAGING</span>}
          </Box>

          {isMdUp && <NavDesktop leftRoutes={leftRoutes} rightRoutes={rightRoutes} />}

          <Stack spacing={2} flexGrow={1} direction="row" alignItems="center" justifyContent="flex-end">
            <Stack spacing={1} direction="row" alignItems="center">
              <div style={{ marginRight: "8px" }}>
                <ShoppingCart numberOfItemsInBasket={numberOfItemsInBasket} />
              </div>
              {isAuthenticated && (
                <UserProfile name={name} email={emailAddress} logout={logout} isSuperuser={isSuperuser} />
              )}
            </Stack>

            {isMdUp && (
              <div style={{ display: "flex", gap: "1rem" }}>
                {!isAuthenticated && (
                  <Button
                    variant="outlined"
                    color="inherit"
                    onClick={() => {
                      const url = getLoginPageUrl(window);
                      navigate(url);
                    }}
                    style={{ width: "70px" }}
                  >
                    Login
                  </Button>
                )}

                <EnrolNowButton />
              </div>
            )}
          </Stack>

          {!isMdUp && <NavMobile leftRoutes={leftRoutes} rightRoutes={rightRoutes} isAuthenticated={isAuthenticated} />}
        </Container>
      </Toolbar>

      {isOffset && <HeaderShadow />}
    </AppBar>
  );
}
